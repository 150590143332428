import { useState, useEffect, useRef  } from "react";
import Order from "../../components/Order";
import { OrderStates, useOrders } from "okeoke.client";

export default function OrdersDone (props) {

    const { orders } = useOrders()
    const [showOrders, setShowOrders] = useState([])
    const [visibleOrders, setVisibleOrders] = useState([])
    const [layout, setLayout] = useState("layout-0")

    const [page, setPage] = useState(0)
    const pageRef = useRef(0)
    const pageTimer = useRef(null)

    useEffect(() => {
        let newOrders = Object.values(orders).filter(o => [OrderStates.DONE].includes(o.orderState))
        let orderCount = newOrders.length
        setShowOrders(newOrders)
        let orderLayout = "layout-0"
        if(orderCount > 1 && orderCount < 3) orderLayout = "layout-2"
        if(orderCount > 2 && orderCount < 5) orderLayout = "layout-4"
        if(orderCount > 4 && orderCount < 7) orderLayout = "layout-6"
        if(orderCount > 6 && orderCount < 10) orderLayout = "layout-9"
        if(orderCount > 9) orderLayout = "layout-12"
        setLayout(orderLayout)
    }, [orders])

    useEffect(() => {
        pageRef.current = page
        if(page === 0 && showOrders.length > 12) {
            setVisibleOrders(showOrders.slice(0,12))
        } else {
            if(showOrders.length<=12) {
                setVisibleOrders([...showOrders])
            } else {
                let visOrderNew = []
                for(let i = (page * 12); i < showOrders.length && i < page * 12 + 12; i++) {
                    visOrderNew.push(showOrders[i])
                }
                let visCount = visOrderNew.length
                if(visCount < 12) {
                     for(let i = 0; i < 12 - visCount; i++) {
                         visOrderNew.push(showOrders[i])
                     }
                 }
                setVisibleOrders(visOrderNew)
            }
        }
    }, [page, showOrders])

    useEffect(() => {
        let orderCount = showOrders.length
        if(orderCount > 12) startPageTimer()
        else clearTimeout(pageTimer.current)
    }, [showOrders])

    const advancePage = () => {
        let pages = Math.floor(showOrders.length / 12)
        setPage(pageRef.current < pages ? (page + 1) : 0)
        pageTimer.current = null
        startPageTimer()
    }

    const startPageTimer = () => {
        if(pageTimer.current != null) return null
        pageTimer.current = setTimeout(advancePage, 3000)
    }


    return (
        <div className="orders-done-root">
            <div className="font-upper font-largest text-center my-05 font-bold">Átvehető</div>
            <div className={`orders-done-orders-container ${layout}`}>
                {visibleOrders.map((order, index) => <Order key={index} order={order} />)}
            </div>
        </div>
    )
}
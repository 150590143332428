import { ReactComponent as Bakery } from './bakery.svg'
import { ReactComponent as Cocktail } from './cocktail.svg'
import { ReactComponent as Coffee } from './coffee.svg'

export default function Order (props) {

    const { order } = props

    let orderDone = order?.orderState > 50

    return <div className={`order-${orderDone ? "done-root" : "preparing-root"} hidden appear`}>
        <div className={`order-inner-container`}>
            {orderDone && <div className="order-message text-center font-bold font-upper color-success">Rendelés elkészült</div>}
            <div className="order-number">
                {(order?.orderNumber || "").split("/")[1]}
            </div>
            <div className="order-icons">
                <div className="svg-container"><Coffee /></div>
                <div className="svg-container"><Bakery /></div>
                <div className="svg-container"><Cocktail /></div>
            </div>
        </div>
    </div>
}
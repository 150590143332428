import React from 'react'
import './style/main.scss'
import './style/device/deviceActivation.scss'
import {
  OrderEngineProvider,
  OkeFrameProvider,
  OkeDeviceProvider
} from 'okeoke.client'
import Orders from './views/Orders'

function App() {
  return (
    <OkeFrameProvider>
      <OkeDeviceProvider prefix={'orderscreen'} types={[1,2,3]} title={'Order Screen'}>
        <OrderEngineProvider>
          <Orders />
        </OrderEngineProvider>
      </OkeDeviceProvider>
    </OkeFrameProvider>
  )
}

export default App
